import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from "../assets/HomeIcon/whatsapp.png";
import TelegramAppIcon from "../assets/HomeIcon/telegram-icon.png";
import QRIcon from "../assets/HomeIcon/qr-code.png";

export default function HomePage() {

  return (
    <div
      className="w-full h-full flex justify-center items-center"
      style={{ backgroundColor: "#e5e7eb" }}
    >
      <div className=""></div>
      <div className="w-full container flex gap-5 justify-center items-center flex-col flex-1 md:flex-row">
        <Link to="/whatsapp">
          <div className="w-44 flex card shadow-sm justify-center rounded-md cursor-pointer hover:shadow-md ">
            <img src={WhatsAppIcon} className="h-40" alt="" />
          </div>
        </Link>
        <Link to="/telegram">
          <div className="w-44 flex card shadow-sm justify-center rounded-md cursor-pointer hover:shadow-md ">
            <img src={TelegramAppIcon} className="h-40" alt="" />
          </div>
        </Link>
        <Link to="/upi">
          <div className="w-44 flex card shadow-sm justify-center rounded-md cursor-pointer hover:shadow-md ">
            <img src={QRIcon} className="h-40" alt="" />
          </div>
        </Link>
      </div>
    </div>
  );
}
