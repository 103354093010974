import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import QRCode from "react-qr-code";

function UPIApp() {
  const [amount, setAmount] = useState(null);
  const [upiLinks, setUpiLinks] = useState([]);
  const [qrPopup, setQrPopup] = useState(false);
  const [upiPopup, setUpiPopup] = useState(false);
  const [qrImage, setQrImage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    upiLink: "",
    setDefault: false,
  });

  useEffect(() => {
    let localData = localStorage.getItem("kk-upi");
    if (localData) {
      let newLocalData = JSON.parse(atob(localData));
      setUpiLinks(newLocalData);
    }
  }, []);

  const generateQr = () => {
    if (upiLinks?.length === 0) {
      alert("Please add UPI id");
      return;
    }
    let upiDetails = upiLinks?.find((upiLink) => upiLink.setDefault);
    if (amount && amount !== 0) {
      let paymentUrl = `upi://pay?pa=${upiDetails.upiLink}&tn=Kalpavriksha Clinic&cu=INR&am=${amount.toFixed(
        2
      )}`;
      
      setQrImage(paymentUrl);
      setQrPopup(true);
      setAmount(0);

      // let url = `https://upiqr.in/api/qr?name=${upiDetails.name}&vpa=${
      //   upiDetails.upiLink
      // }&amount=${amount.toFixed(2)}`;

      // axios
      //   .get(url)
      //   .then((response) => {
      //     let data = response.data;
      //     setQrImage(data);
      //     setQrPopup(true);
      //     setAmount(0);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    } else {
      alert("Please enter amount");
    }
  };

  const metaTags = () => {
    return (
      <Helmet>
        <meta name="title" content="Create UPI QR for With Amount" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />

        <title>Send WhatsApp Messages Without Saving Contact</title>
        <link rel="icon" href="%PUBLIC_URL%/favicon_io/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        <link
          rel="apple-touch-icon"
          href="%PUBLIC_URL%/favicon_io/android-chrome-192x192.png"
        />
      </Helmet>
    );
  };

  const qrCodePopup = () => {
    return (
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center p-4 text-center h-full sm:p-0">
            <div className="relative transform overflow-hidden w-4/5 rounded-lg bg-white text-left shadow-xl transition-all">
              <div className="bg-gray-50 px-3 pb-4 pt-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="flex flex-row justify-between w-full">
                    <h3
                      className="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Scan and Pay
                    </h3>
                    <button
                      type="button"
                      className="bold"
                      onClick={() => setQrPopup(false)}
                    >
                      &#10005;
                    </button>
                  </div>
                </div>
              </div>
              <div className=" px-4 py-3">
                {/* <div
                  className="w-full"
                  dangerouslySetInnerHTML={{ __html: qrImage }}
                /> */}
                <div style={{ height: "auto", margin: "0 auto", maxWidth: 250, width: "100%" }}>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={qrImage}
                    viewBox={`0 0 256 256`}
                    title={`Kalesh React`}
                  />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const formSave = () => {
    if (formData.name.trim() === "") {
      alert("Please enter a name");
      return;
    }
    if (formData.upiLink.trim() === "") {
      alert("Please enter a UPI id");
      return;
    }
    if (!/^[\w.-]+@[\w.-]+$/.test(formData.upiLink.trim())) {
      alert("Please enter a valid UPI id");
      return;
    }

    if (upiLinks?.length === 0) {
      formData.setDefault = true;
    }

    let formDataListCopy = [...upiLinks];
    formDataListCopy.push(formData);
    localStorage.setItem("kk-upi", btoa(JSON.stringify(formDataListCopy)));
    setUpiLinks(formDataListCopy);
    setFormData({ name: "", upiLink: "", setDefault: false });
    setUpiPopup(false);
  };

  const inputValueUpdate = (key, value) => {
    let formDataCopy = { ...formData };
    formDataCopy[key] = value;
    setFormData(formDataCopy);
  };

  const addUpiPopup = () => {
    setFormData({ name: "", upiLink: "", setDefault: false });
    setUpiPopup(true);
  };

  const upiFormPopup = () => {
    return (
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center p-4 text-center h-full sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-gray-50 px-3 py-2">
                <div className="flex flex-row justify-between items-center w-full">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="modal-upi"
                  >
                    Add UPI
                  </h3>
                  <div className="flex flex-row gap-2">
                    <button
                      type="button"
                      className="bg-white border py-2 px-3 rounded"
                      onClick={() => setUpiPopup(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="bg-orange-600 border py-2 px-4 text-white rounded"
                      onClick={() => formSave()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="p-3 flex flex-col w-full">
                <input
                  type="text"
                  className="py-1 px-2 rounded-sm border border-slate-300 mb-5"
                  placeholder="Name *"
                  autoFocus
                  value={formData.name}
                  onChange={(e) => inputValueUpdate("name", e.target.value)}
                />
                <input
                  type="text"
                  className="py-1 px-2 rounded-sm border border-slate-300"
                  placeholder="Upi Id *"
                  value={formData.upiLink}
                  onChange={(e) => inputValueUpdate("upiLink", e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteUpi = (index) => {
    let upiDetails = [...upiLinks];
    upiDetails.splice(index, 1);
    localStorage.setItem("kk-upi", btoa(JSON.stringify(upiDetails)));
    setUpiLinks(upiDetails);
  };

  const updateDefaultUpi = (index, value) => {
    if (value) return;
    let upiDetails = [...upiLinks];
    let newDetails = upiDetails.map((item) => ({ ...item, setDefault: false }));
    newDetails[index].setDefault = true;
    localStorage.setItem("kk-upi", btoa(JSON.stringify(newDetails)));
    setUpiLinks(newDetails);
  };

  const renderGrid = () => {
    return (
      <div className="flex flex-row w-full flex-wrap gap-2 ">
        {upiLinks.map((person, index) => (
          <div className="flex w-full md:w-1/4 gap-1 p-2 flex-row justify-between border shadow bg-white">
            <div className="flex  flex-col">
              <div className="">{person.name}</div>
              <div className="">{person.upiLink}</div>
            </div>
            <div className="flex flex-col items-center">
              <input
                type="checkbox"
                className="border p-2 my-2 mx-3"
                name=""
                checked={person.setDefault}
                onClick={() => updateDefaultUpi(index, person.setDefault)}
              />
              <button className="p-0" onClick={() => deleteUpi(index)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full h-full md:h-screen lg:h-screen bg-gray-200">
      {metaTags()}
      <div className="flex flex-col border-b-gray-400 border w-full">
        <div className="flex flex-col container mx-auto">
          <div className="flex w-full flex-row justify-between items-center self-center my-5 px-3 md:px-11 lg:px-11">
            <div
              className="flex flex-row items-center border border-gray-300 px-3 py-2 rounded"
              onClick={() =>
                window.open(`https://whatsappdirectmessage.zadekunbi.com/`, "")
              }
            >
              <div className="bg-orange-400 text-white cursor-pointer rounded-full p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 48 48"
                >
                  <path d="M39.5,43h-9c-1.381,0-2.5-1.119-2.5-2.5v-9c0-1.105-0.895-2-2-2h-4c-1.105,0-2,0.895-2,2v9c0,1.381-1.119,2.5-2.5,2.5h-9	C7.119,43,6,41.881,6,40.5V21.413c0-2.299,1.054-4.471,2.859-5.893L23.071,4.321c0.545-0.428,1.313-0.428,1.857,0L39.142,15.52	C40.947,16.942,42,19.113,42,21.411V40.5C42,41.881,40.881,43,39.5,43z"></path>
                </svg>
              </div>
            </div>
            <div
              className=" px-3 py-2 underline cursor-pointer"
              onClick={() =>
                window.open(
                  `https://whatsappdirectmessage.zadekunbi.com/whatsapp`,
                  ""
                )
              }
            >
              Using <span className="text-green-400 font-medium">WhatsApp</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col container mx-auto h-4/5 justify-center px-3 md:px-0">
        <div className="flex flex-col justify-center self-center items-center w-full">
          <div className="flex flex-row justify-center self-center items-center text-lg font-semibold text-center mt-5 mb-11">
            Generate QR Code With Amount
          </div>
          <div className="flex flex-row justify-center items-center self-center flex-wrap mb-11  w-full">
            <input
              type="number"
              className="py-1 px-2 rounded-sm border border-slate-300"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(parseFloat(e.target.value))}
            />
            <button
              type="button"
              className="sm:w-auto py-1 px-5 bg-orange-600 hover:bg-orange-700 disabled:bg-orange-300 dark:disabled:bg-orange-800 text-white dark:disabled:text-orange-400 rounded-md shadow focus:outline-none cursor-pointer mt-2 md:ml-2 sm:w-4/12  sm:ml-0 md:mt-0"
              onClick={() => generateQr()}
            >
              <div className="flex flex-row justify-evenly ">
                Generate &nbsp;
                <svg
                  fill="#ffffff"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-112.64 -112.64 737.28 737.28"
                  width="25px"
                  height="25px"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g>
                        <path d="M0,0v233.739h233.739V0H0z M200.348,200.348H33.391V33.391h166.957V200.348z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="66.783"
                          y="66.783"
                          width="100.174"
                          height="100.174"
                        ></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M278.261,0v233.739H512V0H278.261z M478.609,200.348H311.652V33.391h166.957V200.348z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="345.043"
                          y="66.783"
                          width="100.174"
                          height="100.174"
                        ></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M0,278.261V512h233.739V278.261H0z M200.348,478.609H33.391V311.652h166.957V478.609z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="66.783"
                          y="345.043"
                          width="100.174"
                          height="100.174"
                        ></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="345.043,311.652 345.043,278.261 278.261,278.261 278.261,512 345.043,512 345.043,478.609 311.652,478.609 311.652,411.826 345.043,411.826 345.043,378.435 311.652,378.435 311.652,311.652 "></polygon>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="478.609"
                          y="278.261"
                          width="33.391"
                          height="33.391"
                        ></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="478.609,356.174 478.609,478.609 445.217,478.609 445.217,512 512,512 512,356.174 "></polygon>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="378.435"
                          y="278.261"
                          width="66.783"
                          height="33.391"
                        ></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon points="445.217,378.435 445.217,345.043 378.435,345.043 378.435,445.217 445.217,445.217 445.217,411.826 411.826,411.826 411.826,378.435 "></polygon>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="378.435"
                          y="478.609"
                          width="33.391"
                          height="33.391"
                        ></rect>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </button>
            <button
              type="button"
              className="ml-2 sm:w-auto py-1 px-5 bg-gray-600 hover:bg-gray-700 disabled:bg-gray-300 dark:disabled:bg-gray-800 text-white dark:disabled:text-gray-400 rounded-md shadow focus:outline-none cursor-pointer mt-2 md:ml-2 sm:w-4/12  sm:ml-0 md:mt-0"
              onClick={() => addUpiPopup()}
            >
              + Add UPI
            </button>
          </div>
        </div>
        {qrPopup && qrCodePopup()}
        {upiPopup && upiFormPopup()}
        {upiLinks?.length > 0 && renderGrid()}
        {/* <div className="flex flex-row justify-center self-center items-center text-center my-11">
          Just fill in the phone number you want to WhatsApp in international
          form e.g. +91 98XXXXXXXX No login or registration on this website
          needed. <br />
          You do need an active WhatsApp account. This website does not use
          cookies and is very privacy friendly.
        </div>
        <div className="flex flex-row justify-center self-center items-center text-center my-11">
          How to send WhatsApp message without saving the phone number? That is
          what this WhatsApp direct service is for. <br />
          You want to sent a WhatsApp message without going to the trouble of
          adding someone to your contact list. <br />
          You just don't want to bloat your contacts with everyone you ever have
          sent a WhatsApp message. This service is for you:
        </div> */}
      </div>

      <div className="flex flex-col bg-gray-900 ">
        <div className="flex flex-col container mx-auto">
          <div className="flex flex-col text-white text-xs items-center mt-5 px-3 md:px-11 lg:px-11">
            Privacy: We only use regular web-server logging for debugging and
            statistical purposes. No further information is recorded. No cookies
            are used. Phone numbers are not sent to our server or stored on our
            server. Phone numbers are sent directly to the WhatsApp servers.
            This service is not associated with Meta, Facebook or WhatsApp in
            any way.
          </div>
          <div className="flex flex-col text-white text-xs items-center mb-5 mt-3 px-3 md:px-11">
            © 2023. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default UPIApp;
