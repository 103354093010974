import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WhatsApp from "./pages/WhatsApp";
import TelegramApp from "./pages/TelegramApp";
import HomePage from "./pages/HomePage";
import UPIApp from "./pages/UPIApp";
// import LoadingApp from "./pages/LoadingApp";
// import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="/whatsapp" element={<WhatsApp />}></Route>
        <Route exact path="/" element={<TelegramApp />}></Route> */}
        {/* <Route exact path="/loading" element={<LoadingApp />}></Route> */}
        {/* <Route path="*" element={<NoPage />} />*/} 
        <Route exact path="/" element={<HomePage />}></Route>
        <Route exact path="/whatsapp" element={<WhatsApp />}></Route>
        <Route exact path="/telegram" element={<TelegramApp />}></Route>
        <Route exact path="/upi" element={<UPIApp />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
